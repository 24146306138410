import React from 'react';

import Form from '../components/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faPhone, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons';
import contactStyle from '../assets/css/contact.module.css';
import SEO from '../components/seo';

class Contact extends React.Component {
  goBack = () => {
    window.history.back();
  };
  render() {
    return (
      <div className={contactStyle.container}>
        <SEO
          title="Nous contacter | Agence digitale | MDS Digital Agency"
          description="N'hésitez pas à nous contacter pour plus d'informations, une demande de devis, etc"
          keywords="contact"
        />
        <div className={contactStyle.split_l}>
          <button className={contactStyle.back} onClick={this.goBack}>
            <FontAwesomeIcon icon={faChevronLeft} /> retour
          </button>
        </div>
        <div className={contactStyle.split_r}>
          <div className={contactStyle.form_container}>
            <h3>Parlons de votre projet</h3>
            <Form />
          </div>
          <div className={contactStyle.info}>
            <ul>
              <li>
                <FontAwesomeIcon icon={faHome} />{' '}
                <a
                  href="https://goo.gl/maps/4Ukhfe9bQpJ2"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Boulevard Initialis, 7/1 7000 Mons
                </a>
              </li>
              <li>
                <FontAwesomeIcon icon={faEnvelope} />{' '}
                <a href="mailto:info@mds-digitalagency.be">info@mds-digitalagency.be</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
